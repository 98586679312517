import { useMutation, useQueryClient } from 'react-query';
import supabase from '../supabase';

export const useUpdateJobQuoteDetails = () => {
    const queryClient = useQueryClient();
    return useMutation(
      async ({ jobId, quoteDetails }) => {
        const { data, error } = await supabase
          .from('jobs')
          .update({ quoteDetails })
          .eq('id', jobId);
  
        if (error) {
          throw error;
        }
  
        return data;
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('jobs');
        },
      }
    );
  };