import React from "react";

import { Document, Page, Image, View, Text, Font, StyleSheet, Link } from "@react-pdf/renderer";

import { Table } from "./Table/Table";
import { Description } from "./Description";
import { Terms } from "./Terms";

import { quoteColumns, additionalItemsColumns } from "./columns";
import { Heading, ClientInfo } from "./Heading";

import TermsOfTrade1 from "../../../TermsOfTrade1.jpg";
import TermsOfTrade2 from "../../../TermsOfTrade2.jpg";

export function DownloadPDF({ quote }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Heading quote={quote} />
        <Text style={styles.quoteText}>Quotation</Text>
        <ClientInfo quote={quote} />
        <Description description={quote.description} />
        <Text style={styles.heading}>Line Items</Text>
        <Table invoice={quote.quote_lines} columns={quoteColumns} groupable />

        {quote?.quote_addons.length ? (
          <View>
            <Text style={styles.heading}>Additional Items</Text>
            <Table
              type="Additional"
              invoice={quote.quote_addons}
              columns={additionalItemsColumns}
            />
          </View>
        ) : null}

        <Text style={styles.subTextBoldRed}>All prices exclude GST</Text>

        <Text style={styles.subTextBold}>
          *Hire charges start on the date on which the erection of the scaffolding is completed and
          continue until the date of dismantling of the scaffolding as advised by the client
          (off-hire date). Both dates will be charged as whole days.
        </Text>

        <Text style={styles.subTextMargin}>
          Alterations or extra work will be charged at $90.00 plus GST/hour/man plus hire charges
          for additional scaffolding material. Extra work includes but is not limited to all
          modifications, moves, repairs, partly dismantling or adjustments of the scaffolding.
        </Text>
      </Page>
      <Page size="A4" style={styles.page}>
        <View>
          <Terms terms={quote.terms} />
        </View>

        <View>
          <Text style={styles.subTextMargin}>
            Please confirm your acceptance of this Quote and enclosed Terms of Trade either online
            or via email reply to
          </Text>
          <Text style={styles.subTextMargin}>
            Thank you very much for considering our services.
          </Text>
          <Text style={styles.subTextMargin}>Kind regards</Text>
          <Text style={styles.heading}>Office Manager & Operations Support</Text>
        </View>

        <View style={styles.footer} fixed>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            fixed
          />
        </View>
        <Link
              src="https://aqvmprmysyrcpvaqztcp.supabase.co/storage/v1/object/public/job-files/TCs.doc"
              style={styles.pdfLink}
              target="_blank" 
            >
              View T&Cs
        </Link>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    // paddingTop: 10,
    paddingBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#1A8140",
    marginTop: 8,
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headingContact: {
    textAlign: "right",
    marginTop: 20,
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 7,
  },
  quoteText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 18,
  },
  subText: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    // fontWeight: "semibold",
  },
  subTextMargin: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    marginTop: 15,
    // fontWeight: "semibold",
  },
  subTextBold: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
  },
  subTextBoldRed: {
    fontFamily: "Open Sans",
    color: "red",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
    textAlign: "right",
  },
  logo: {
    objectFit: "contain",
    width: "20%",
    height: "auto",
    paddingTop: 20,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    right: 0,
  },
  pageTermsOfTrade: {
    objectFit: "contain",
    width: "105%",
    height: "100%",
    marginLeft: "-20",
  },
  footerLogo: {
    objectFit: "contain",
    width: "110%",
    // height: "auto",
    marginRight: 0,
  },

  footerText: {
    fontSize: 6,
    paddingLeft: 10,
  },

  pageNumber: {
    // position: "absolute",
    marginTop: 10,
    marginRight: 17,
    // paddingLeft: 70,
    fontSize: 8,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },
});
