import { useMutation } from "react-query";
import supabase from "../../supabase";


export async function createImages(image) {
    const { data, error } = await supabase.from("quote_images").insert(image);
    if (error) {
      console.log(error);
      throw new Error(error.messge);
    }
    return data;
  }
  
  export const useCreateImages = () =>
    useMutation((image) => createImages(image), {
      onSuccess: (data) => data,
      onError: (error) => error,
      mutationFn: createImages,
    });
  