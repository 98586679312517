/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import { SideModal, Input, TimeSelect, Dropdown, AddressJob, DateSelect } from "../../common";

import { useClients } from "../../api/Clients";
import { useStaff } from "../../api/Staff";
import { useCreateJob, useUpdateJob, fetchJob } from "../../api/Jobs";
import { useCreateVisit } from "../../api/Visits";
import { fetchAllContacts } from "../../api/ClientContacts";

const statusOptions = [{ value: "Pending Start Date", label: "Pending Start Date" }];

const brandingOptions = [{ value: "Capital", label: "Capital" }];

export const clientTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Construction", label: "Construction" },
  { value: "Civil", label: "Civil" },
  { value: "Residential", label: "Residential" },
];

export function CreateJob({ heading, open, setOpen, formType = "create" }) {
  const [jobPayload, setJobPayload] = useState({});
  const clientData = useClients();
  const staffData = useStaff();
  const [contactId, setContactId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [client, setClient] = useState(null);

  const createJobMutation = useCreateJob(jobPayload);
  const createVisitMutation = useCreateVisit();

  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);
  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);
  const renderClientList = () => {
    if (clientData?.data && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const renderStaffList = () => {
    if (staffData?.data && staffData?.data?.length > 0) {
      return staffData.data.map((staff) => ({
        label: staff.staff_name,
        value: staff.id,
      }));
    }
    return [];
  };
  return (
    <div>
      <Formik
        initialValues={{
          client_id: null,
          clientType: "",
          branding: "",
          site: "",
          start_date: moment().utc(),
          end_date: moment().add(3, "M").utc(),
          truck_driver: null,
          supervisor: null,
          job_status: "Pending Start Date",
          status: "Active",
          descriptionOfQuote: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const {
            client_id,
            jobType,
            clientType,
            site,
            start_date,
            end_date,
            truck_driver,
            supervisor,
            staff_ids,
            staff_labels,
            job_status,
            status,
            descriptionOfQuote,
          } = values;
          const start = moment(start_date).format("DD/MM/YYYY");
          const end = moment(end_date).format("DD/MM/YYYY");
          const dateList = enumerateDaysBetweenDates(start, end);

          let visitsCreated;

          if (start_date && end_date) {
            visitsCreated = true;
          } else {
            visitsCreated = false;
          }
          const jobPayload = {
            client_id,
            jobType,
            clientType,
            branding: "",
            site: values.street,
            start_date: start_date ? moment(start_date).format("DD/MM/YYYY") : "",
            end_date: end_date ? moment(end_date).format("DD/MM/YYYY") : "",
            truck_driver,
            supervisor,
            job_status,
            staff_ids,
            staff_labels,
            visits_created: visitsCreated,
            status,
            descriptionOfQuote,
          };
          console.log(jobPayload, "jobPayload");
          try {
            const jobRes = await createJobMutation.mutateAsync(jobPayload);
            const jobId = jobRes?.[0]?.id;
            if (start_date && end_date) {
              const visits = dateList.map((date) => ({
                date,
                job_id: jobId,
                team_leader_id: supervisor,
                staff_ids,
                staff_labels,
                visit_status: "Pending Prestart",
                status: "Active",
              }));
              const visitRes = await createVisitMutation.mutateAsync(visits);
            }
          } catch (err) {
            console.log("ERROR CREATING JOB", err);
          }

          setSubmitting(false);
          setOpen(false);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading={heading}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType={formType}
          >
            <div className="flex items-center">
              <Dropdown
                label="Client"
                id="client_id"
                value={values.client_id}
                onChange={(opt, value) => {
                  setFieldValue("client_id", value);
                  setClientId(value);
                  setClient(value);
                }}                onBlur={setFieldTouched}
                isLoading={clientData.isLoading}
                options={renderClientList()}
              />
              <Dropdown
                        label="Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                      />
              <Dropdown
                label="Job Type"
                id="jobType"
                value={values.jobType}
                onChange={(opt, value) => setFieldValue("jobType", value)}
                onBlur={setFieldTouched}
                options={clientTypeOptions}
              />

              {/* <Dropdown
                label="Client Type"
                id="clientType"
                value={values.clientType}
                onChange={(opt, value) => setFieldValue("clientType", value)}
                onBlur={setFieldTouched}
                options={clientTypeOptions}
              /> */}
            </div>
            <div className="flex-column">
              <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>
              <AddressJob
                fullAddress={values.fullAddress}
                streetId="street"
                streetId2="street2"
                cityId="city"
                postalId="postal"
                suburbId="suburb"
                streetVal={values.street}
                street2Val={values.street2}
                cityVal={values.city}
                postalVal={values.postal}
                suburbVal={values.suburb}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              <Input
                title="Internal - Scope of Works"
                id="descriptionOfQuote"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.descriptionOfQuote}
              />
            </div>
            <div className="w-1/2">
              <Dropdown
                label="Status"
                id="job_status"
                value={values.job_status || "Pending Start Date"}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                options={statusOptions}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}

function enumerateDaysBetweenDates(startDate, endDate) {
  const end = moment(endDate, "DD/MM/YYYY");
  const start = moment(startDate, "DD/MM/YYYY");
  const result = [moment({ ...start })];

  if (end.diff(start, "days") >= 0) {
    while (end.date() !== start.date()) {
      start.add(1, "day");
      result.push(moment({ ...start }));
    }
  }

  return result.map((x) => x.format("DD/MM/YYYY"));
}
