/* eslint-disable no-unused-vars */
import React from "react";
import moment from "moment";
import { Formik } from "formik";
import { SideModal, Input, Dropdown, DateSelect } from "../../common";

import { VehiclesApi } from "../../api";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const operationalOptions = [
  { value: "Operational", label: "Operational" },
  { value: "Issue", label: "Issue" },
];

export function CreateVehicle({ heading, open, setOpen, formType = "create" }) {
  const createVehicleMutation = VehiclesApi.useCreateVehicle();

  return (
    <div>
      <Formik
        initialValues={{
          rego: "",
          code: "",
          make: "",
          model: "",
          odometer: "",
          hubo: "",
          ruc: 0,
          rego_due: "",
          wof_due: "",
          service_due_date: "",
          service_due: "",
          vehicle_status: "Operational",
          status: "Active",
          date: "",
          lights: "",
          clean_windscreen: "",
          cab_tidy: "",
          tyres_depth: "",
          tyres_psi: "",
          washer_fluid: "",
          oil_level: "",
          battery_level: "",
          cooland_level: "",
          add_blue: "",
          dpf_level: "",
          vehicle_accident_sheets: "",
          load_charts: "",
          business_cards: "",
          hazard_sheets: "",
          rescue_rope_scaff_hook: "",
          first_aid_kit: "",
          comment: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const vehiclePayload = {
            rego: values.rego || "",
            code: values.code || "",
            make: values.make || "",
            model: values.model || "",
            odometer: values.odometer || "",
            hubo: values.hubo || "",
            ruc: Number(values.ruc) || 0,
            rego_due: values.rego_due
              ? moment(values.rego_due, "DD/MM/YYYY").format("DD/MM/YYYY")
              : "",
            wof_due: values.wof_due
              ? moment(values.wof_due, "DD/MM/YYYY").format("DD/MM/YYYY")
              : "",
            service_due_date: values.service_due_date
              ? moment(values.service_due_date, "DD/MM/YYYY").format("DD/MM/YYYY")
              : "",
            service_due: values.service_due,
            vehicle_status: values.vehicle_status,
            status: values.status,
            date: values.date ? moment(values.date, "DD/MM/YYYY").format("DD/MM/YYYY") : "",
            lights: values.lights,
            clean_windscreen: values.clean_windscreen,
            cab_tidy: values.cab_tidy,
            tyres_depth: values.tyres_depth,
            tyres_psi: values.tyres_psi,
            washer_fluid: values.washer_fluid,
            oil_level: values.oil_level,
            battery_level: values.battery_level,
            cooland_level: values.cooland_level,
            add_blue: values.add_blue,
            dpf_level: values.dpf_level,
            vehicle_accident_sheets: values.vehicle_accident_sheets,
            load_charts: values.load_charts,
            business_cards: values.business_cards,
            hazard_sheets: values.hazard_sheets,
            rescue_rope_scaff_hook: values.rescue_rope_scaff_hook,
            first_aid_kit: values.first_aid_kit,
            comment: values.comment,
          };
          try {
            await createVehicleMutation.mutateAsync(vehiclePayload);
          } catch (err) {
            console.log("error", err);
          }
          setOpen(false);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading={heading}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType={formType}
          >
            <div className="flex w-1/2">
              <Input
                title="Vehicle Rego"
                id="rego"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.rego}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Make"
                id="make"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.make}
              />
              <Input
                title="Model"
                id="model"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.model}
              />
            </div>

            <div className="flex w-1/2">
              <Input
                title="Code Name"
                id="code"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.code}
              />
            </div>
            <div className="flex items-center">
              <DateSelect
                title="Rego Due"
                id="rego_due"
                value={values.rego_due}
                onChange={setFieldValue}
              />
              <DateSelect
                title="WOF Date"
                id="wof_due"
                value={values.wof_due}
                onChange={setFieldValue}
              />
            </div>

            <div className="flex items-center">
              <DateSelect
                title="Service Due Date"
                id="service_due_date"
                value={values.service_due_date}
                onChange={setFieldValue}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Hubometer"
                id="hubo"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.hubo}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="RUC"
                id="ruc"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.ruc}
              />
            </div>

            <div className="flex items-center">
              <Dropdown
                label="Operational Status"
                id="vehicle_status"
                value={values.vehicle_status}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                options={operationalOptions}
              />
              <Dropdown
                label="Status"
                id="status"
                value={values.status}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                options={statusOptions}
              />
            </div>

            <div className="flex items-center">
              <DateSelect title="Date" id="date" value={values.date} onChange={setFieldValue} />
              <Input
                title="Lights"
                id="lights"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.lights}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Clean Windscreen"
                id="clean_windscreen"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.clean_windscreen}
              />
              <Input
                title="Cab Tidy"
                id="cab_tidy"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.cab_tidy}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Tyres Depth"
                id="tyres_depth"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.tyres_depth}
              />
              <Input
                title="Tyres Psi"
                id="tyres_psi"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.tyres_psi}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Washer Fluid"
                id="washer_fluid"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.washer_fluid}
              />
              <Input
                title="Oil Level"
                id="oil_level"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.oil_level}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Battery Level"
                id="battery_level"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.battery_level}
              />
              <Input
                title="Cooland Level"
                id="cooland_level"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.cooland_level}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Add Blue"
                id="add_blue"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.add_blue}
              />
              <Input
                title="DPF Level"
                id="dpf_level"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.dpf_level}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Vehicle Accident Sheets"
                id="vehicle_accident_sheets"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.vehicle_accident_sheets}
              />
              <Input
                title="Load Charts"
                id="load_charts"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.load_charts}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Business Cards"
                id="business_cards"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.business_cards}
              />
              <Input
                title="Hazard Sheets"
                id="hazard_sheets"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.hazard_sheets}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Rescue Rope Scaff Hook"
                id="rescue_rope_scaff_hook"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.rescue_rope_scaff_hook}
              />
              <Input
                title="First Aid Kit"
                id="first_aid_kit"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.first_aid_kit}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Comment"
                id="comment"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.comment}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
