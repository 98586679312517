import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";

export function Address({
  streetId,
  streetId2,
  cityId,
  postalId,
  suburbId,
  streetVal,
  street2Val,
  cityVal,
  postalVal,
  suburbVal,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  const googleApiKey = "AIzaSyDilh_PqwMiRf38mynksFmFQDLpaA_pOzQ";

  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [suburb, setSuburb] = useState("");

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    let street2 = "";
    let city = "";
    let postalCode = "";
    let suburb = "";

    addressComponents.forEach((component) => {
      const { types } = component;

      if (types.includes("street_number")) {
        if (street2 === "") {
          street2 = component.long_name;
        } else {
          street2 += ` ${component.long_name}`;
        }
      }

      if (types.includes("route")) {
        if (street2 === "") {
          street2 = component.long_name;
        } else {
          street2 += ` ${component.long_name}`;
        }
      }

      if (types.includes("sublocality")) {
        if (suburb === "") {
          suburb = component.long_name;
        } else {
          suburb += `, ${component.long_name}`;
        }
      }

      if (types.includes("locality")) {
        if (city === "") {
          city = component.long_name;
        } else {
          city += `, ${component.long_name}`;
        }
        cityVal = city;
      }

      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    setStreet2(street2);
    setCity(city);
    setPostalCode(postalCode);
    setSuburb(suburb);

    setFieldValue("street2", street2);
    setFieldValue("street", place.formatted_address);
    setFieldValue("postal", postalCode);
    setFieldValue("city", city);
    setFieldValue("suburb", suburb);
  };

  useEffect(() => {
    setStreet(streetVal);
    setStreet2(street2Val);
    setCity(cityVal);
    setPostalCode(postalVal);
    setSuburb(suburbVal)
  }, [streetVal, cityVal, street2Val, postalVal,suburbVal]);

  return (
    <div className="px-4 py-4 mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-6 md:gap-6">
        <div className="col-span-6">
          <Autocomplete
            apiKey={googleApiKey}
            placeholder=""
            style={{ width: "100%" }}
            className="border border-gray-300 rounded-md focus:ring-indigo-500 p-2"
            onPlaceSelected={(place) => {
              handlePlaceSelect(place);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "nz" },
            }}
            defaultValue={street}
          />
        </div>

        <div className="col-span-3">
          <label htmlFor={streetId2} className="block text-sm font-medium text-gray-700">
            Street
          </label>
          <input
            type="text"
            name={streetId2}
            id={streetId2}
            autoComplete="street-address"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={street2}
          />
        </div>

        <div className="col-span-3">
          <label htmlFor={streetId2} className="block text-sm font-medium text-gray-700">
            Suburb
          </label>
          <input
            type="text"
            name={suburbId}
            id={suburbId}
            autoComplete="suburb"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={suburb}
          />
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-4">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            City
          </label>
          <input
            type="text"
            name={cityId}
            id={cityId}
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={city}
          />
        </div>
        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
          <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
            Postal Code
          </label>
          <input
            type="text"
            name={postalId}
            id={postalId}
            autoComplete="postal-code"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={postalCode}
          />
        </div>
      </div>
    </div>
  );
}
