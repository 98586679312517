import { useMutation } from "react-query";
import supabase from "../../supabase";

export async function updateLines(lines) {
  const linesForUpdate = lines.filter(e => e.id !== undefined)
  const linesForCreate = lines.find(e => e.id === null)
  
  const resultUpdate = await supabase.from("quote_lines").upsert(linesForUpdate); 
  const resultInsert = await supabase.from("quote_lines").insert(linesForCreate);

  const data = { ...resultInsert.data, ...resultUpdate.data }; 

  return data;
}

export const useUpdateLines = () =>
  useMutation((lines) => updateLines(lines), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: updateLines,
  });
