import supabase from "../../supabase";

export async function fetchClientAndContacts(id) {
    const { data, error } = await supabase
      .from("quotes")
      .select(
        `clients:client(id, client_name),
        client_contacts(*)`
      )
      .eq("id", Number(id));
    if (error) {
      throw new Error(error.message);
    }
    return data[0];
  }