import React, { useEffect, useState } from "react";
import { CursorClickIcon } from "@heroicons/react/solid";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { JobsApi, QuotesApi } from "../../api";
import supabase from "../../api/supabase";
import { TwoColumnDetails, Section } from "../../common/Details";
import { Spinner, Tabs } from "../../common";
import { JobTasks } from "../../components/Jobs/Tasks/JobTaskTable";
import { HandoverForm } from "../../components/Jobs/Handover";
import { FileList } from "../../components/Files";
import { VisitsMain } from "./Visits";
import { VariationTasks } from "../../components/Jobs/Tasks";
import { VisitTimesheetTable } from "../../components/Jobs/VisitTimesheets";
import { TagTable } from "../../components/Jobs/TagsTable";
import { HireInvoices } from "../../components/Jobs/HireInvoices";
import { EDInvoices } from "../../components/Jobs/EDInvoices";
import { DayWorkTask } from "../../components/Jobs/DayWorkTask";
import { JobvsHours } from "../../components/Jobs/JobVShours";
import { Thumbnails } from "../../components/Quote/Thumbnails";

export const JobDetails = () => {
  const user = supabase.auth.user();
  const { jobId } = useParams(0);
  const location = useLocation();
  const history = useHistory();

  const [tabIndex, setTabIndex] = useState(1);

  const items = [
    { label: "Tasks", id: 1 },
    { label: "Visits", id: 2 },
    { label: "Visit Timesheets", id: 3 },
    { label: "Scaffold Tags", id: 4 },
    { label: "Weekly Hire Invoices", id: 5 },
    { label: "ED Invoices", id: 6 },
    { label: "Job Files", id: 7 },
    { label: "Quoted hours vs. Actual hours", id: 8 },
  ];

  const [refreshData, setRefreshData] = useState(false);

  const { data, isLoading, refetch } = JobsApi.useFetchJob(jobId);
  const [clientData, setClientData] = useState(null);
  const [clientLoading, setClientLoading] = useState(false);
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    if (data?.quote_id && !Number.isNaN(data?.quote_id)) {
      setClientLoading(true);
      QuotesApi.fetchClientAndContacts(data?.quote_id)
        .then((clientResponse) => {
          setClientData(clientResponse);
          setClientLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setClientLoading(false);
        });
    }
    setJobData(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refreshData]);

  const quoteLink = () => {
    const path = `quotes/${data.quote_id}/details`;
    history.push("/");
    history.push(path);
  };

  if (isLoading || clientLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!data) return null;

  const editPage = {
    pathname: `/jobs/${jobId}/editJob`,
    state: { background: location, name: "editJob" },
  };

  const handover = data?.job_handover?.[0];

  function isValidDate(date, format = "DD/MM/YYYY") {
    return moment(date, format, true).isValid();
  }
  return (
    <div className="w-full mx-auto mt-8 mb-28">
      <TwoColumnDetails heading="Job Details" editBtn="Edit Job" editLink={editPage} isEditable>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="mb-6">
              <div className="mb-4">
                <Section title="Job #" content={data?.job_num} />
              </div>
              <div className="mb-4">
                <Section title="Job Type" content={data?.jobType} />
              </div>
              <div className="mb-4">
                <Section title="Client" content={data.clients?.client_name} />
              </div>
              <div className="mb-4">
                <Section title="Site" content={data?.site} />
              </div>
              <div className="mb-4">
                <Section title="Internal - Scope of Works" content={data?.descriptionOfQuote} />
              </div>
              <div className="mb-4">
                <Section title="Quote Confirmation Details" content={data?.quoteDetails} />
              </div>
              <div className="mb-4">
                <Section
                  title="Start Date"
                  content={isValidDate(data?.start_date) ? data?.start_date : "Invalid Date"}
                />
              </div>
              <div className="mb-4">
                <Section title="Finish Date" content={data?.end_date} />
              </div>
            </div>
            <div className="mb-4">
              {" "}
              {/* Increased from mb-4 to mb-6 */}
              <Section
                title="Job Status"
                content={
                  <div>
                    {data?.job_status}
                    <HandoverForm
                      jobId={jobId}
                      handover={data?.job_handover}
                      client_id={data?.client_id}
                      onSubmit={async () => {
                        console.log("Submitting form");
                        await refetch();
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>

          <div>
            <div className="mb-6">
              {" "}
              {/* Increased from mb-4 to mb-6 */}
              <Section title="Active Status" content={data.status} />
            </div>
            <div className="mb-6">
              {" "}
              {/* Increased from mb-4 to mb-6 */}
              <h2 className="py-2 text-lg font-medium leading-5 tracking-wide">H&S Info</h2>
              <Section title="H&S Officer" content={handover?.hs_officer || "Shawn Parahi"} />
              <Section
                title="H&S Officer Phone #"
                content={handover?.hs_officer_phone || "027 225 9515"}
              />
              <Section
                title="H&S Officer Email"
                content={handover?.hs_officer_email || "shawn@capscaf.nz"}
              />
            </div>

            <div className="mb-6">
              {" "}
              {/* Increased from mb-4 to mb-6 */}
              <h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
                Client Contact Info
              </h2>
              <Section
                title="Client Site Contact"
                content={handover?.site_forman || clientData?.client_contacts?.name || ""}
              />
              <Section
                title="Client Site Contact Phone"
                content={handover?.site_forman_phone || clientData?.client_contacts?.phone || ""}
              />
              <Section
                title="Client Site Contact Email"
                content={handover?.site_forman_email || clientData?.client_contacts?.email || ""}
              />
            </div>
          </div>
        </div>
      </TwoColumnDetails>

      <h2 className="text-lg px-8 mb-4 leading-6 font-large text-gray-900 mt-6 ml-1">
        Site Photos
      </h2>
      <div className="grid grid-cols-6 gap-4 mx-8 mb-4">
        {data?.quote_images?.map((image, index) => (
          <Thumbnails key={index} link={image.link} />
        ))}
        {data?.quote_images.length > 0 ? "" : <p className="px-2">No Site Photos</p>}
      </div>

      {/* 
      jobData?.quote_id && (
        <div className="px-8">
          <button
            type="button"
            onClick={quoteLink}
            className="flex items-center text-blue-600 text-md hover:text-blue-800"
          >
            <CursorClickIcon className="w-4 h-4" />
            <span className="pl-2">View Quote</span>
          </button>
        </div>
      ) */}

      <div className="px-8">
        <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
      </div>
      {tabIndex === 1 && (
        <>
          <JobTasks jobId={jobId} tasks={data?.job_tasks} quote_id={data?.quote_id} />
          <VariationTasks jobId={jobId} />
        </>
      )}
      {tabIndex === 2 && (
        <div className="mb-8">
          <VisitsMain jobId={jobId} />
        </div>
      )}
      {tabIndex === 3 && (
        <div className="mb-8">
          <VisitTimesheetTable jobId={jobId} />
        </div>
      )}

      {tabIndex === 4 && (
        <div className="mb-8">
          <TagTable jobId={jobId} />
        </div>
      )}
      {tabIndex === 5 && (
        <div className="mb-8">
          <HireInvoices jobId={jobId} />
        </div>
      )}
      {tabIndex === 6 && (
        <div className="mb-8">
          <EDInvoices job_ID={jobId} />
        </div>
      )}
      {tabIndex === 7 && (
        <div className="mb-8">
          <FileList title="Job Notes & Files" column="job_id" type="jobs" id={jobId} />
        </div>
      )}
      {tabIndex === 8 && (
        <div className="mb-8">
          <JobvsHours jobId={jobId} tasks={data?.job_tasks} quote_id={data?.quote_id} />
        </div>
      )}
    </div>
  );
};
