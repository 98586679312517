import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import moment from "moment";
import supabase from "../supabase";
import { useNotificationStore } from "../../store/notifications";

export async function updateTagByJob({ status, job_id }) {
    const day = moment();
    const { data, error } = await supabase.from("scaffold_tags").select("*").match({ job_id: Number(job_id) });

    if (error) {
        throw new Error(error.message);
    }

    const newRows = data.map(e => {
        const diff = moment(e.inspection_due, "DD/MM/YYYY").diff(day, "days");
        if (status === "In Progress" && diff < 0) {
            return { id: e.id, status: "Inactive" }
        }
        if (status === "In Progress" && diff >= 0) {
            return { id: e.id, status: "Active" }
        }
        if (status === "Completed") {
            return { id: e.id, status: "Inactive" }
        }
        return null
    }).filter(e => e !== null);

    for (const row of newRows) {
        supabase.from("scaffold_tags").update({ status: row.status }).match({ id: row.id })
            .then(data => updateAppenateTags(data.data))
    }

}

async function updateAppenateTags(data) {
    const tagPayload = [];

    data.map((tag) =>
        tagPayload.push([
            tag.id, // id
            tag.tag_no || "",
            tag.job_id || "",
            tag.description || "",
            tag.last_inspection || "",
            tag.inspection_due || "",
            tag.status || "",
        ]),
    );
    return axios.post("https://orange-server.herokuapp.com/api/data-sync", {
        id: "3651216c-2a71-4d26-b74c-af7f016c9573",
        data: tagPayload,
    });
}
