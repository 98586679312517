import React, { useState } from "react";

import { ClientForm } from "../../components/Clients";

export const EditClient = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <ClientForm heading="Update Client" open={open} setOpen={setOpen} formType="edit" />
    </>
  );
};
