/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { RadioButton } from "primereact/radiobutton";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import clsx from "clsx";
import supabase from "../../api/supabase";

import { ClientForm } from "../Clients";
import { ContactForm } from "../Contacts";
import { Input, TextArea, Dropdown, Address, QuoteTab, Button, Spinner } from "../../common";

import { QuoteLines } from "./QuoteLines";
import { Rates } from "./Rates/Rates";
import { AdminRates } from "./Rates/AdminRates";
import { AdditionalItems } from "./AdditionalItems/AdditionalItems";
import { Totals } from "./QuoteTotals";
import { useClients } from "../../api/Clients";
import { useStaff } from "../../api/Staff";
import { useJobs } from "../../api/Jobs";
import { useFetchQuote,useFetchQuoteImages } from "../../api/Quotes";
import { fetchAllContacts } from "../../api/ClientContacts";

import { useNotificationStore } from "../../store/notifications";

import {
  formatAddons,
  formatQuoteLines,
  formatQuotePortalLines,
  formatPortalAddons,
  formatQuoteAdditionalConditions,
  formatZones,
  formatRates,
  tabs,
  Admintabs,
  zoneOptions,
  quoteRates,
  formatImages
} from "./utils";

import { QuotesApi } from "../../api";
import { TickBox } from "../../common/Form/TickBox";
import { AdditionalConditionsQuote } from "./AdditionalConditionsQuote/AdditionalConditionsQuote";
import { QuotePhotos } from "./QuotePhotos";
import { CreatePDF } from "./CreatePDF";

export const quoteTypeOptions = [
  { value: "New", label: "New" },
  { value: "Variation", label: "Variation" },
];

export const clientTypeOptions = [
  { value: "Residential ", label: "Residential " },
  { value: "Commercial", label: "Commercial" },
  { value: "ShrinkWrap", label: "ShrinkWrap" },
  { value: "Painting", label: "Painting" },
  { value: "Roof edge", label: "Roof edge" },
];

const brandingOptions = [{ value: "Capital", label: "Capital" }];

const estimatedWayOptions = [
  { value: "SqM", label: "SqM" },
  { value: "Hours", label: "Hours" },
  { value: "Total ED", label: "Total ED" },
];

export function QuoteForm({ formType = "create" }) {
  const history = useHistory();
  const [redirectOutput, setRedirectOutput] = useState(false);
  const { addNotification } = useNotificationStore();

  const user = supabase.auth.user();

  const quotePathArray = useLocation().pathname.split("/");

  const tabsForQuote =
  quotePathArray[2] === "add-quote"
    ? Admintabs
    : tabs;

  const [loggedUser, setLoggedUser] = useState(null);
  const { data } = useFetchQuoteImages(quoteId);
  // Client Form
  const [clientForm, setClientForm] = useState(false);
  const [clientId, setClientId] = useState(null);

  // Contact Form
  const [contactForm, setContactForm] = useState(false);
  const [contactId, setContactId] = useState(null);

  const [transportRate, setTransportRate] = useState(0);
  const [fetchedQuote, setFetchedQuote] = useState([]);
  const [quoteCreating, setQuoteCreating] = useState(false);

  // Quote Lines
  const [estimatedWay, setEstimatedWay] = useState(null);
  const [quoteLines, setQuoteLines] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [sharedCheckBoxesState, setsharedCheckBoxesState] = useState([]);

  const [termValues, setTermValues] = useState({
    standardRate: 70,
    nonStandardRate: 100,
    smallTruck: 200,
    hiabTruck: 300,
  });

  // Quote Phases
  const [zoneValues, setZoneValues] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([{ id: 1, label: "Scaffold", zone_id: "" }]);
  const [zones, setZones] = useState(null);

  // Totals
  const [weekTotal, setWeekTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [transportTotal, setTransportTotal] = useState(0);
  const [erectDismantleTotal, setErectDismantleTotal] = useState(0);
  const [additionalTotal, setAdditionalTotal] = useState(0);

  // Curent Quote Tab
  const [currentTab, setCurrentTab] = useState("Phases");

  const [quoteNum, setQuoteNum] = useState(null);
  const [client, setClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [rates, setRates] = useState([]);
  const clientData = useClients();
  const staffData = useStaff();
  const jobData = useJobs();

  const { quoteId } = useParams();

  useEffect(async () => {
    if (formType === "create") {
      const ratesData = await QuotesApi.fetchRates();
      setRates(ratesData);
      const additionalConditions = await QuotesApi.fetchAdditionalConditions();
      setsharedCheckBoxesState(additionalConditions);
    }

    // Generate next quote #
    const quotes = await QuotesApi.getQuoteNum();
    const lastQuoteNum = quotes?.job_num?.split("-")?.[0];
    const nextNumSeq = lastQuoteNum ? Number(lastQuoteNum) + 1 : 1000;
    setQuoteNum(`${String(nextNumSeq)}-1`);
    handleZoneChange(1);
  }, []);

  useEffect(() => {
    renderClientList();
  }, [clientId]);

  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);

  useEffect(async () => {
    let isCurrent = true;

    if (quoteId) {
      const quote = await QuotesApi.fetchQuote(quoteId);
      setZones(quote?.quote_zones?.length);
      if (quote) {
        setsharedCheckBoxesState(quote?.quote_additional_conditions);
      }
      if (isCurrent) {
        const contactsRes = await fetchAllContacts(quote?.client);
        let contacts;
        if (contactsRes && contactsRes.length > 0) {
          contacts = contactsRes.map((contact) => ({
            label: contact.name,
            value: contact.id,
          }));
        } else {
          contacts = [];
        }

        setContactList(contacts);
        setFetchedQuote(quote);
        setRates(quote.quote_rates);

        const formatQuoteLines = formatQuotePortalLines(quote?.quote_lines);
        const formQuoteAddons = formatPortalAddons(quote?.quote_addons);
        const zoneLineItems = zoneOptions.slice(0, quote?.quote_zones.length);

        const labelsArr = quote?.quote_zones.map((item, index) => ({
          zone_id: item.id,
          id: item.zone_id,
          label: String(item.zone_label).trim(),
        }));

        setZoneLabels(labelsArr);
        setEstimatedWay(quote.estimatedWay);
        setZoneValues(zoneLineItems);
        setQuoteLines(formatQuoteLines);
        setAdditionalItems(formQuoteAddons);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [quoteId]);

  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);

  useEffect(() => {
    let totalWeek = 0;
    let totalAmount = 0;
    let totalAdditionalItems = 0;
    let totalErectDismantle = 0;

    if (quoteLines.length) {
      totalWeek += quoteLines.reduce((acc, curr) => acc + Number(curr.hireFee), 0);
      totalErectDismantle += quoteLines.reduce((acc, curr) => acc + Number(curr.dismantle), 0);
    }
    if (additionalItems.length) {
      totalAdditionalItems += additionalItems.reduce(
        (acc, curr) => acc + Number(curr.totalCost),
        0,
      );
    }

    totalAmount += totalErectDismantle + totalAdditionalItems + totalWeek;

    setTotal(totalAmount);
    setWeekTotal(totalWeek);
    setErectDismantleTotal(totalErectDismantle);
    setAdditionalTotal(totalAdditionalItems);
  }, [quoteLines, additionalItems, erectDismantleTotal, additionalTotal]);

  // MUTATIONS
  const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const updateZonesMutation = QuotesApi.useUpdateZones();
  const updateLinesMutation = QuotesApi.useUpdateLines();
  const updateAddonsMutation = QuotesApi.useUpdateAddons();
  const updateRatesMutation = QuotesApi.useUpdateRates();
  const updateAdditionalConditions = QuotesApi.useUpdateAdditionalConditions();
  const updateImagesMutation = QuotesApi.useUpdateImages();


  const deleteQuoteZoneMutation = QuotesApi.useDeleteQuoteZone();
  const deleteQuoteLines = QuotesApi.useDeleteLines();

  const createQuoteMutation = QuotesApi.useCreateQuote();
  const createZonesMutation = QuotesApi.useCreateZones();
  const createLinesMutation = QuotesApi.useCreateLines();
  const createAddonsMutation = QuotesApi.useCreateAddons();
  const createRatesMutation = QuotesApi.useCreateRates();
  const createImagesMutation = QuotesApi.useCreateImages();
  const createAdditionalConditions = QuotesApi.useCreateAdditionalConditions();

  const renderClientList = () => {
    if (clientData && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data
        .filter((staff) => staff.type === 'Office')
        .map((staff) => ({
          label: staff.staff_name,
          value: staff.id,
          email: staff.email,
        }));
    }
    return [];
  };

  const renderJobList = () => {
    if (jobData && jobData?.data?.length > 0) {
      return jobData.data.map((job) => ({
        label: `${job.job_num} - ${job.site}`,
        value: job.id,
      }));
    }
    return [];
  };

  const renderContactList = () => {};

  const handleZoneChange = (val) => {
    const zoneVal = Number(val);

    const labelsArr = Array(zoneVal)
      .fill()
      .map((obj, index) => ({
        zone_id: zoneLabels[index]?.zone_id || "",
        id: index + 1,
        label: zoneLabels[index]?.label || "",
      }));
    setZoneLabels(labelsArr);
    setZones(val);

    const zoneLineItems = zoneOptions.slice(0, zoneVal);
    setZoneValues(zoneLineItems);
  };

  const handleZoneLabelChange = (val, index) => {
    setZoneLabels(
      zoneLabels.map((item) => {
        if (index === Number(item.id)) {
          return { ...item, label: val.target.value };
        }
        return item;
      }),
    );
  };

  const loggedInUser = () => {
    const staffArr = staffData?.data?.find((staff) => staff.email === user?.email);
    return staffArr?.id;
  };

  if (!rates.length || quoteNum === null) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (quoteId && !fetchedQuote.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  return (
    <div className="mb-10">
      <div className="">
        <Formik
          initialValues={{
            quote_type: "New",
            PO_Number: "",
            variation_job_id: fetchedQuote.variation_job_id || "",
            quote_num: formType === "edit" ? `${fetchedQuote.quote_num}` : `${quoteNum}`,
            branding: fetchedQuote.branding || "Capital",
            clientType: fetchedQuote.clientType || "",
            client: fetchedQuote.client || clientId,
            version: fetchedQuote?.version || 1,
            estimator: fetchedQuote?.estimator || loggedInUser(),
            contact: fetchedQuote.contact_id || contactId,
            maxZones: fetchedQuote.max_zones ? String(fetchedQuote.max_zones) : "1",
            description:
              fetchedQuote.description || "",
            street: fetchedQuote?.street_1 || "",
            street2: fetchedQuote?.street_2 || "",
            city: fetchedQuote?.city || "",
            postal: fetchedQuote.post_code || "",
            transport_total: fetchedQuote || transportRate,
            terms: fetchedQuote?.terms || "",
            status: fetchedQuote?.status || "Pending",
            estimatedWay: fetchedQuote?.estimatedWay || null,
            PDF: fetchedQuote?.PDF || ""
          }}
          validate={(values) => {
            const errors = {};
            if (values.quote_type === "Variation" && !values.variation_job_id) {
              errors.variation_job_id = "Job is required.";
            }
            if (!values.estimatedWay) {
              errors.estimatedWay = "How will this job be estimated? is required";
            }

            /*
            if (!values.quote_num) {
              errors.quote_num = "Quote # is required.";
            }
            if (!values.client) {
              errors.client = "Client is required.";
            }
            if (!values.estimator) {
              errors.estimator = "Estimator is required.";
            }
            */
            const zoneEmpty = zoneLabels.find((e) => e.label === "");

            if (zoneEmpty !== undefined) {
              errors.zoneLabels = "Is required.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setQuoteCreating(true);
            const {
              quote_type,
              PO_Number,
              variation_job_id,
              quote_num,
              client,
              version,
              contact,
              estimator,
              clientType,
              maxZones,
              description,
              street,
              street2,
              city,
              postal,
              terms,
              status,
              estimatedWay,
              PDF
            } = values;

            const quotePayload = {
              quote_type,
              PO_Number,
              variation_job_id: variation_job_id || null,
              quote_num,
              version,
              clientType,
              branding: values.branding || "",
              client: client || null,
              contact_id: contact || null,
              estimator: estimator || null,
              created_by: user?.user_metadata?.name,
              max_zones: maxZones,
              description,
              street_1: street,
              street_2: street2,
              city,
              post_code: postal,
              terms: String(terms),
              transport_total: Number(transportTotal),
              weekly_total: Number(weekTotal) || null,
              total_amount: Number(total) || null,
              status,
              estimatedWay,
              additionalTotal: Number(additionalTotal),
              erectDismantleTotal: Number(erectDismantleTotal),
              PDF
            };
            if (formType === "edit") {
              try {
                const quoteResult = await updateQuoteMutation.mutateAsync(
                  {
                    quote: quotePayload,
                    quoteId: fetchedQuote?.id,
                  },
                  {
                    onSuccess: (payload) => {
                      const zones = formatZones(zoneLabels, fetchedQuote?.id, "edit");
                      const lines = formatQuoteLines(quoteLines, fetchedQuote?.id, "edit").filter(
                        (line) => Number(line.zone) <= Number(maxZones),
                      );
                      const addons = formatAddons(additionalItems, fetchedQuote?.id, "edit");
                      const formatedRates = formatRates(rates, fetchedQuote?.id, "edit");
                      const formatedImages = formatImages(values, fetchedQuote?.id, "edit", data);
                      const formatedQuoteAdditionalConditions = formatQuoteAdditionalConditions(
                        sharedCheckBoxesState,
                        quoteId,
                        "edit",
                      );
                      // eslint-disable-next-line array-callback-return, consistent-return
                      // const deletedLines = formatQuoteLines(
                      //   quoteLines,
                      //   fetchedQuote?.id,
                      //   "edit",
                      // ).map((line) => {
                      //   if (Number(line.zone) > Number(maxZones)) return line.id;
                      // });
                      // deleteQuoteLines.mutate(deletedLines, {
                      //   onSuccess: (payload) => {},
                      //   onError: (error) => console.log("error", error),
                      // });
                      const deletedNumber = maxZones - fetchedQuote?.quote_zones?.length;
                      if (deletedNumber < 0) {
                        const deletedZones = fetchedQuote?.quote_zones
                          .slice(deletedNumber)
                          .map((item) => item.id);
                        deleteQuoteZoneMutation.mutate(deletedZones, {
                          onSuccess: (payload) => {},
                          // eslint-disable-next-line no-console
                          onError: (error) => console.log("error", error),
                        });
                      }

                      updateZonesMutation.mutate(zones, {
                        onSuccess: (payload) => {},
                        // eslint-disable-next-line no-console
                        onError: (error) => console.log("error", error),
                      });

                      updateLinesMutation.mutate(lines, {
                        onSuccess: (payload) => {},
                        // eslint-disable-next-line no-console
                        onError: (error) => console.log("error", error),
                      });

                      updateRatesMutation.mutate(formatedRates, {
                        // eslint-disable-next-line no-console
                        onError: (error) => console.log("error", error),
                      });
                      
                      updateImagesMutation.mutate(formatedImages, {
                        // eslint-disable-next-line no-console
                        onError: (error) => console.log("error", error),
                      });

                      updateAdditionalConditions.mutate(formatedQuoteAdditionalConditions, {
                        // eslint-disable-next-line no-console
                        onError: (error) => console.log("error", error),
                      });
                      updateAddonsMutation.mutate(addons, {
                        onSuccess: (payload) => {
                          setQuoteCreating(false);

                          if (redirectOutput) {
                            history.push(`/quotes/${fetchedQuote?.id}/output`);
                          } else {
                            history.push("/quotes");
                          }

                          addNotification({
                            isSuccess: true,
                            heading: "Success!",
                            content: `Successfully updated quote!`,
                          });
                        },
                        onError: (error) => {
                          setQuoteCreating(false);

                          history.push("/quotes");
                          addNotification({
                            isSuccess: false,
                            heading: "Failure!",
                            content: `Failed to update quote. ${error?.message}`,
                          });
                        },
                      });
                    },
                    // eslint-disable-next-line no-console
                    onError: (error) => console.log("error", error),
                  },
                );
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log("error", err);
              }
            } else {
              createQuoteMutation.mutate(quotePayload, {
                onSuccess: async(payload) => {
                  const quoteId = payload?.[0]?.id;

                  // Format payloads
                  const zones = formatZones(zoneLabels, quoteId);
                  const addons = formatAddons(additionalItems, quoteId);
                  const lines = formatQuoteLines(quoteLines, quoteId).filter(
                    (line) => Number(line.zone) <= Number(maxZones),
                  );
                  const formatedRates = formatRates(rates, quoteId);
                  const formatedQuoteAdditionalConditions = formatQuoteAdditionalConditions(
                    sharedCheckBoxesState,
                    quoteId,
                  );
                  const formatedImages = formatImages(values, quoteId);
                  // CREATE ZONES
                  createZonesMutation.mutate(zones, {
                    // eslint-disable-next-line no-console
                    onError: (error) => console.log("error", error),
                  });

                  await createImagesMutation.mutate(formatedImages, {
                    // eslint-disable-next-line no-console
                    onError: (error) => console.log("error", error),
                    onSuccess: () => {},
                  });
                  // CREATE ZONES
                  createLinesMutation.mutate(lines, {
                    // eslint-disable-next-line no-console
                    onError: (error) => console.log("error", error),
                  });

                  createRatesMutation.mutate(formatedRates, {
                    // eslint-disable-next-line no-console
                    onError: (error) => console.log("error", error),
                  });
                  // CREATE ADDITIONAL CONDITIONS
                  createAdditionalConditions.mutate(formatedQuoteAdditionalConditions, {
                    // eslint-disable-next-line no-console
                    onError: (error) => console.log("error", error),
                  });
                  // CREATE QUOTE ADDONS
                  createAddonsMutation.mutate(addons, {
                    onSuccess: (payload) => {
                      setQuoteCreating(false);

                      history.push(`/quotes/${quoteId}/details`);
                      addNotification({
                        isSuccess: true,
                        heading: "Success!",
                        content: `Successfully created quote!`,
                      });
                    },
                    onError: (err) => {
                      setQuoteCreating(false);

                      // history.push("/quotes");
                      addNotification({
                        isSuccess: false,
                        heading: "Failure!",
                        content: `Failed to create quote. ${err?.message}`,
                      });
                    },
                  });
                },
                onError: (err) => {
                  setQuoteCreating(false);
                },
              });
            }
            console.timeEnd("CREATING");
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div className="w-full">
              <div className="flex">
                <form onSubmit={handleSubmit} id="quoteForm" className="w-4/6">
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="New / Variation"
                        id="quote_type"
                        value={values.quote_type}
                        onChange={(opt, value) => {
                          setFieldValue("quote_type", value);
                          // setClientId(value);
                          // setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        options={quoteTypeOptions}
                      />
                    </div>
                  </div>

                  {values.quote_type === "Variation" && (
                    <div className="flex">
                      <div className="w-1/2">
                        <Input
                          title="PO Number"
                          id="PO_Number"
                          type="text"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.PO_Number}
                          error={errors.PO_Number}
                        />
                      </div>
                      <div className="w-1/2">
                        <Dropdown
                          label="Job"
                          id="variation_job_id"
                          value={values.variation_job_id}
                          onChange={async (opt, value) => {
                            setFieldValue("variation_job_id", value);

                            // reset field values
                            setFieldValue("client", "");
                            setFieldValue("contact", "");
                            setFieldValue("street", "");
                            setFieldValue("street2", "");
                            setFieldValue("city", "");
                            setFieldValue("postal", "");
                            // Find matching job and quote
                            const jobMatch = jobData.data.find((job) => job.id === value);

                            if (jobMatch && jobMatch.quote_id) {
                              try {
                                const { data, error } = await supabase
                                  .from("quotes")
                                  .select("*")
                                  .eq("id", Number(jobMatch.quote_id));
                                const quote = data?.[0];
                                if (quote) {
                                  const oldQuoteNumber = quote?.quote_num?.split("-");
                                  let { data } = await supabase.from("quotes");
                                  data = data.map((row) => row.quote_num.split("-"));
                                  data = data.filter((e) => oldQuoteNumber[0] === e[0]);

                                  setFieldValue("client", quote?.client);
                                  setFieldValue(
                                    "quote_num",
                                    `${oldQuoteNumber[0]}-V${data.length}`,
                                  );
                                  setClientId(quote?.client);
                                  setClient(quote?.client);
                                  setFieldValue("clientType", quote?.clientType || "");
                                  setFieldValue("maxZones", String(quote?.max_zones) || "");
                                  setFieldValue("contact", quote?.contact_id || "");
                                  setFieldValue("street", quote?.street_1 || "");
                                  setFieldValue("street2", quote?.street_2 || "");
                                  setFieldValue("city", quote?.city || "");
                                  setFieldValue("postal", quote?.post_code || "");
                                  setFieldValue("estimator", quote?.estimator || "");
                                }
                              } catch (err) {
                                console.log("error", err);
                              }
                            }
                            // setClientId(value);
                            // setClient(value);
                          }}
                          error={errors.variation_job_id}
                          onBlur={setFieldTouched}
                          options={renderJobList()}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Client"
                        id="client"
                        value={values.client}
                        onChange={(opt, value) => {
                          setFieldValue("client", value);
                          setClientId(value);
                          setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        isLoading={clientData.isFetching}
                        options={renderClientList()}
                        // error={errors.client}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon className="w-6 h-6 text-indigo-500" />
                          <button
                            type="button"
                            className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
                            onClick={() => setClientForm(true)}
                          >
                            Add New Client
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label="Job Type"
                        id="clientType"
                        value={values.clientType}
                        onChange={(opt, value) => setFieldValue("clientType", value)}
                        onBlur={setFieldTouched}
                        options={clientTypeOptions}
                      />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Dropdown
                      label="Phases"
                      id="maxZones"
                      value={values.maxZones}
                      onChange={(opt, value) => {
                        setFieldValue("maxZones", value);
                        handleZoneChange(value);
                      }}
                      // onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={zoneOptions}
                    />
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Input
                        title="Quote #"
                        id="quote_num"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.quote_num}
                        // error={errors.quote_num}
                        disabled
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label="Site Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon
                            className={clsx(
                              !clientId ? "text-indigo-100" : "text-indigo-500",
                              "w-6 h-6",
                            )}
                          />
                          <button
                            type="button"
                            className={clsx(
                              !clientId
                                ? "text-gray-200 cursor-none"
                                : "text-gray-600 hover:text-gray-800",
                              "pl-1 font-semibold leading-5 text-sm",
                            )}
                            onClick={() => setContactForm(true)}
                            disabled={!clientId}
                          >
                            Add New Contact
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <TextArea
                    title="Internal - Scope of Works"
                    id="description"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                  />
                  <div className="w-1/2">
                    <Dropdown
                      label="Estimator"
                      id="estimator"
                      // filterByEmail={values.estimator === user?.email}
                      value={values.estimator}
                      onChange={(opt, value) => {
                        setFieldValue("estimator", value);
                      }}
                      onBlur={setFieldTouched}
                      isLoading={staffData.isFetching}
                      options={renderStaffList()}
                      // error={errors.estimator}
                    />
                  </div>
                  <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>
                  <Address
                    fullAddress={values.fullAddress}
                    streetId="street"
                    streetId2="street2"
                    cityId="city"
                    postalId="postal"
                    suburbId="suburb"
                    streetVal={values.street}
                    street2Val={values.street2}
                    cityVal={values.city}
                    postalVal={values.postal}
                    suburbVal={values.suburb}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                  <h3 className="block text-sm font-medium text-gray-700 w-1/2 ml-4 mt-1">
                    How will this job be estimated?
                  </h3>
                  <div className="w-1/2 ml-4 mt-1 grid grid-cols-3 gap-3">
                    {/* <div>
                      <RadioButton
                        inputId="howestimateSqM"
                        name="howestimateSqM"
                        value="SqM"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "SqM"}
                      />
                      <label htmlFor="howestimateSqM" className="p-radiobutton-label ml-1">
                        SqM
                      </label>
                    </div> */}
                    <div>
                      <RadioButton
                        inputId="howestimateHours"
                        name="howestimateHours"
                        value="Hours"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "Hours"}
                      />
                      <label htmlFor="howestimateHours" className="p-radiobutton-label ml-1">
                        Hours
                      </label>
                    </div>

                    <div>
                      <RadioButton
                        inputId="howestimateTotalED"
                        name="howestimateTotalED"
                        value="Total ED"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "Total ED"}
                      />
                      <label htmlFor="howestimateTotalED" className="p-radiobutton-label ml-1">
                        Total ED
                      </label>
                    </div>
                  </div>
                </form>
                <div className="w-full">
                  <div className="w-4/6 mx-auto">
                    <div className="flex justify-start mb-4">
                      <QuoteTab tabs={tabsForQuote} handleChange={(tab) => setCurrentTab(tab)} />
                    </div>
                    {currentTab === "Phases" ? (
                      <>
                        {zones ? (
                          <div className="grid grid-cols-2">
                            {[...Array(Number(zones))].map((_, index) => (
                              <div className="flex items-center" key={index}>
                                <Input
                                  id={index + 1}
                                  title={`Phase ${index + 1}`}
                                  labelInline
                                  placeholder={`Phase ${index + 1}`}
                                  error={errors?.zoneLabels}
                                  defaultValue={
                                    formType === "edit"
                                      ? fetchedQuote?.quote_zones?.[index]?.zone_label
                                      : "Scaffold"
                                  }
                                  handleChange={(val) => handleZoneLabelChange(val, index + 1)}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>Select # of zones to continue</p>
                          </div>
                        )}
                      </>
                    ) : currentTab === "Rates" ? (
                      <Rates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    ) : (
                      <AdminRates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {estimatedWay !== null && (
                <div className="px-4">
                  <QuoteLines
                    quoteLines={quoteLines}
                    setQuoteLines={setQuoteLines}
                    zoneOptions={zoneValues}
                    zoneLabels={zoneLabels}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                  <AdditionalItems
                    items={additionalItems}
                    setItems={setAdditionalItems}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                </div>
              )}
              <div className="px-4">
                <QuotePhotos
                  setFieldValue={setFieldValue}
                  data={formType !== "create" ? data : []}
                />
                <br />
              </div>
              <div className="text-md font-normal leading-5 px-4 mx-auto max-w-xl">
                <h4 className="text-center font-semibold font-md">Attach file</h4>
                <CreatePDF field="PDF" setFieldValue={setFieldValue} />
              </div>
              {/* <div className="w-3/6">
                <TextArea
                  title="Additional Conditions"
                  id="terms"
                  type="text"
                  handleChange={handleChange}
                  value={values.terms}
                  rows={10}
                />
              </div> */}

              <div className="flex">
                <div className="w-1/2">
                  <br />
                  <br />
                  <h3 className="text-lg px-4 leading-6 font-large h3-container">
                    Additional Conditions
                  </h3>
                  <AdditionalConditionsQuote
                    id="terms"
                    type="text"
                    handleChange={handleChange}
                    value={values.terms}
                    sharedCheckBoxesState={sharedCheckBoxesState}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div className="w-1/2">
                  <br />
                  <br />
                  <h3 className="text-lg px-4 leading-6 font-large h3-container">
                    Select Additional Conditions
                  </h3>
                  <br />
                  <TickBox
                    sharedCheckBoxesState={sharedCheckBoxesState}
                    setsharedCheckBoxesState={setsharedCheckBoxesState}
                    setFieldValue={setFieldValue}
                    value={values.terms}
                  />
                </div>
              </div>

              <Totals
                weekTotal={weekTotal}
                total={total}
                erectDismantleTotal={erectDismantleTotal}
                additionalTotal={additionalTotal}
              />
            </div>
          )}
        </Formik>
      </div>
      <div className="pl-4 mt-6">
        {formType === "edit" ? (
          <div className="flex space-x-4">
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && !redirectOutput}
              size="sm"
            >
              Save & Exit
            </Button>
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && redirectOutput}
              size="sm"
              onClick={() => setRedirectOutput(true)}
            >
              Save & View
            </Button>
          </div>
        ) : (
          <Button type="submit" form="quoteForm" isLoading={quoteCreating} size="sm">
            Create Quote
          </Button>
        )}
      </div>
      <ClientForm
        heading="Create Client"
        open={clientForm}
        setOpen={setClientForm}
        setClientId={setClientId}
      />
      <ContactForm
        heading="Create Contact"
        open={contactForm}
        setOpen={setContactForm}
        setContactId={setContactId}
      />
    </div>
  );
}

function formatRateText(rates) {
  // eslint-disable-next-line no-console
  console.log("RATES >>> ", rates);
  return `
  TBC
  `;
}
